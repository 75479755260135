.outer-top-ts {
  background-color: #ecf0f1;
  /* padding-top: 30px; */
}
.cntnr {
  padding-left: 30px;
  padding-right: 30px;
}

.cpn-shwcd {
  display: block;
  background: linear-gradient(to left, #ff006c, #ff003a);
  padding: 8px 22px;
  color: #fff;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  border-bottom: 2px #bf0630 solid;
  cursor: pointer;

}
.cpn-shwcdMobile{
  scale:0.7;
  text-align: center;
}

.lnk {
  transition: all linear 0.2s;
  color: #fff;
  border: none;
  font-size: 13px;
  line-height: 22px;
  padding: 8px 14px 6px 14px;
  font-family: "Barlow", sans-serif;
  border-radius: 4px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}
/* .exp-date { 
    margin-left: 20px;
} */

.sidebar {
  position: sticky;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  overflow: hidden;
  margin-bottom: 10px;
}

.coupon-code {
  font-weight: 600;
}

.itemlist {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 1rem;
}

.item {
  text-decoration: none;
}

.wrapper {
  width: 5000px;
  transition: all 0ms ease 0s;
  transform: translate3d(0px, 0px, 0px) !important;
  /* display: block!important; */
}

.flags {
  text-align: center;
  margin: 0 auto;
}

.flags a {
  text-decoration: none;
}

.activecountry {
  background-color: #ed4053;
}

/* .image-child {
  height: 60px;
  width: 60px;
} */

.image-child {
  width: 7rem;
  background: linear-gradient(202deg,#fddd9a, #fcc955);
  height: 3.5rem;
  margin: 25px 1.5rem;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.carouseldiv {
  margin: 1rem 2.5rem 1rem 2.5rem;
}
.carousel-control-prev {
  width: auto;
  left: 0.2rem;
  top: 50%;
  height: 0.5rem;
}
.carousel-control-next {
  width: auto;
  right: 0.2rem;
  top: 50%;
  height: 0.5rem;
}
.carousel-control-next-icon {
  background-color: #2a2727;
  border-radius: 28px;
  background-size: 24px;
}
.carousel-control-prev-icon {
  background-color: #2a2727;
  border-radius: 28px;
  background-size: 24px;
}

.selected-country {
  color: #bf0630;
}

/* .react-multi-carousel-list {
  padding: 0 !important;
} */

.slider {
  /* margin: 0 20px;
  overflow: hidden; */
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider img {
  width: 50%;
  border-radius: 10px;
  
}

.custom-dot-list-style button {
  border: none;
  background: rgb(255, 68, 68);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
}

.react-multiple-carousel__arrow--left {
  left: unset!important;
    /* bottom: 13.5rem; */
    right:  2.6rem !important;

}

.react-multiple-carousel__arrow--right {
  right:  0.5rem !important;
  /* bottom: 13.5rem; */
}

.react-multiple-carousel__arrow {
  min-width: 2rem !important;
  min-height: 2rem !important;

}

.carousel-button-group{
  position: relative;
  top:-13.9rem
}

.card {
  border: none!important;
}
/* 

.react-multiple-carousel__arrow::before {
  color: black !important;
  font-size: xx-large !important;
} */

.cpn-Image{
  width: 25%;
  margin: 0 auto;
  padding: 1rem;
}

.loader {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: relative;
  display: block;
  margin: 0 auto;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  border-color: #FF3D00;
  animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

.dropbtn { 
  background-color:  #ed4053;
  color: white; 
  padding: 16px; 
  /* width: 19rem; */
  font-size: 16px; 
  border: none; 
  cursor: pointer; 
} 

.dropdown { 
  position: relative; 
  display: inline-block; 
} 

.dropdown-content { 
  display: none; 
  position: absolute; 
  background-color: #f9f9f9; 
  min-width: 160px; 
  box-shadow: 0px 8px 16px  
      0px rgba(0, 0, 0, 0.2); 
  z-index: 1; 
} 

.dropdown-content div { 
  color: black; 
  padding: 12px 16px; 
  text-decoration: none; 
  width: 19rem;
  display: block; 
} 

.dropdown-content div:hover { 
  background-color: #f1f1f1 
} 

.dropdown:hover .dropdown-content { 
  display: block; 
} 

.dropdown:hover .dropbtn { 
  background-color:  #ed4053;
} 

.selected-country-dd{
  background-color:  #ed4053;
}

.tp-store-block {
  height: 10rem;
  border-radius: 3px;
  border: solid 1px #d9e0e2;
  background-color: #ffffff;
  margin-top: 9px;
  display: inline-flex;
  cursor: pointer;
  text-align: left;
  width: 85vw;
}

.products-mobile {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, 280px); */
  width: 100%;
  grid-gap: 0rem;
  /* justify-content: space-between; */
  margin: 0px;
  margin-block-start: 0px;
  padding: 0px;
  list-style: none;

}

.left-cmp{display: flex;
  width: 40%;
  align-items: center;
  margin: 0 auto;
  padding: 0.5rem;
}

.right-cmp{   
  width: 60%;
  text-align: center;
  display: flex;
  justify-content: center;

}

.tp-store-logo img{
  /* position: relative;
  height: auto;
  width: auto;
  max-height: 6rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain; */
  /* width: 50%; */
  /* position: relative; */
  /* background: grey; */
  /* margin: 9px; */
  width: 100%;
  border-radius: 15px;
  /* margin: 25px 1.5rem; */
  margin-bottom: 0px;
  /* background: linear-gradient(143deg,red, grey); */
  display: flex;
  align-items: CENTER;
  height: 3.5rem;
  object-fit: scale-down;
  aspect-ratio: 3/2;
}

.spanwrapper {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.infinite-scroll-component {
  overflow: hidden!important;
}

.coupons-section-inner .coupons-deals .item {
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 10px;
  /* padding: 10px; */
}

.logo-store-name{
  display: inline-flex;
  justify-content: space-between;
  font-size: small;
}

.tp-store-name{
  display: flex;
  align-items: center;
  font-size: small;
  margin-right: 0.5rem;
}

.descriptiontext{
  text-wrap: balance;
}
.expiry{
  font-size: small;
  color: gray;
  height: 1rem;
}

@media only screen and (max-width: 960px) {
  .coupons-section-inner .coupons-deals .item{
    padding: 10px;
    margin-bottom: 1rem;
  background-color: white;
  border-radius: 10px;
  }
}


.tp-store-logo{
    width: 7rem;
    background: linear-gradient(202deg,#fddd9a, #fcc955);
    height: 3.5rem;
    margin: 1px 0.5rem 9px 0.5rem;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}


.offer-value{
  font-size: larger;
}


.dropbtn-country,.dropdown-content,.dropdown-country,.selected-country-dd,.dropdown-content div{
  width: 100%!important;
}