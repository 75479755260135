.name {
    margin-left: 1.5rem;
}
.countrylist{
    width: 120px;
}

.cpn-img {
    border-radius: 999px;
    width: 120px;
    margin: auto;
}

.modal-content{
    border: none;
}

.cpn-name {
    overflow: hidden;
    letter-spacing: normal;
    font-family: 'Barlow', sans-serif;
    line-height: 1.3em;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
}

.cpn-body{
    position: relative;
    padding: 25px;
    
}

.cpn-content {
    text-align: center;
}

.cpn-mid {
    text-align: center;
    font-size: 13px;
    display: block;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #999;
}

.cpn-footer {
    background: url(../../public/assets/images/coupons-bg.png) repeat-x top center;
    color: #fff;
    font-size: 13px;
    /* padding-bottom: 30px; */
    padding: 15px;
    /* text-align: right; */
    border-top: 1px solid #e5e5e5;
}

.cpn-modal-footer {
    text-align: center;
    overflow: hidden;
    letter-spacing: normal;
    font-family: 'Barlow', sans-serif;
    line-height: 1.3em;
    font-size: 22px;
    font-weight: 600;
    color: #fff;
}

.cpn-shwcd {
    display: block;
    background: linear-gradient(to left,#ff006c,#ff003a);
    padding: 8px 22px;
    color: #fff;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: normal;
    border-bottom: 2px #bf0630 solid;
    cursor: pointer;
    text-transform: uppercase;
}
.carousel-indicators {
    margin-bottom: 0px !important;
}
/* .exp-date{
    margin-left: 20px;
} */
.coupon-code {
    font-weight: 600;
}

.subscribe{

    
    color: #fff;
    font-weight: 600;
    background-color: #f55951;
    padding-bottom: 0.5rem;
    display: inline-block;
    border: none;
    border-radius: 0.5em;
    width: auto;
    height: auto;
    margin-bottom: 1.3em;
}

#newsletter1{

    border-radius: 14px;
    margin: 0.5rem;
    line-height: 1.5;
    width: auto;
    border: 1px solid #d9d9d9;
    border-radius: 0.5em;
    margin-bottom: 1em; 
    font-family: auto;
}

.descriptiontext-cover {
    /* text-wrap: balance;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px; */
    line-height: 1.1em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

